import "./Feedback.scss";
import Header from "../header/Header";

import FeedbackTable from "./FeedbackTable";

const Feedback = () => {
  return (
    <div>
      <Header />

      <div className="row">
        <div className="col-12 main-table ms-2 mt-5">
          <FeedbackTable />
        </div>
      </div>
    </div>
  );
};
export default Feedback;
