import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import TribeModal from "../tribeModal/TribeModal";
import { Button, Grid, Select, TextField } from "@mui/material";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useDebounce } from "@uidotdev/usehooks";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },

  {
    id: "star",
    numeric: false,
    disablePadding: false,
    label: "Rating",
  },
  {
    id: "feedback",
    numeric: false,
    disablePadding: false,
    label: "Feedback",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Submitted",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={
              headCell.sortable && orderBy === headCell.id ? order : false
            }
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function FeedbackTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [totalDocs, setTotalDocs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [ratingFilter, setRatingFilter] = useState(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const token = localStorage.getItem("token");

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/admin/user/feedbacks`,
        {
          params: {
            order: order === "asc" ? 1 : -1,
            page: page + 1,
            limit: rowsPerPage,
            sort_by: orderBy,
            ...(ratingFilter ? { star: ratingFilter } : {}),
            ...(debouncedSearchQuery ? { q: debouncedSearchQuery } : {}),
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = result.data.data.docs.map((feedback) => ({
        first_name: feedback.user?.first_name || "No name provided",
        last_name: feedback.user?.last_name || "",
        star: feedback.star,
        feedback: feedback.feedback,
        created_at: feedback.createdAt,
      }));

      setRows(data);
      setTotalDocs(result.data.data.totalDocs);

      // Initialize toggle states with default value true
      const initialToggleStates = {};
      data.forEach((row) => {
        initialToggleStates[row.id] = true; // Default value set to true
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(error?.response?.data?.error);
    }
    setLoading(false);
  }, [
    debouncedSearchQuery,
    order,
    orderBy,
    page,
    ratingFilter,
    rowsPerPage,
    token,
  ]);

  useEffect(() => {
    fetchData();
  }, [page, orderBy, order, rowsPerPage, fetchData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedEventId(null);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", p: 4, mb: 2 }}>
        <h5>Feedback</h5>
        <TableContainer>
          <div className="d-flex my-3">
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              label="Search"
              variant="outlined"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </div>
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item xs={3}>
              <FormControl fullWidth size={"small"}>
                <InputLabel id="rating-label">Rating</InputLabel>
                <Select
                  labelId="rating-label"
                  value={ratingFilter}
                  label={"Rating"}
                  onChange={(e) => {
                    setPage(0);
                    setRatingFilter(e.target.value);
                  }}
                >
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <MenuItem key={rating} value={rating}>
                      {rating}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setPage(0);
                  setRatingFilter(null);
                  fetchData();
                }}
                disabled={!ratingFilter}
              >
                Clear Filter
              </Button>
            </Grid>
          </Grid>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    style={{ textAlign: "center", fontSize: "1rem" }}
                  >
                    Loading...
                  </TableCell>
                </TableRow>
              ) : rows.length ? (
                rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      sx={{ cursor: "pointer" }}
                      key={`${row.first_name}-${row.last_name}-${
                        row.created_at ?? index
                      }`}
                    >
                      <TableCell component="th" id={labelId} scope="row">
                        {row.first_name} {row.last_name}
                      </TableCell>
                      <TableCell>{row.star}</TableCell>
                      <TableCell>{row.feedback}</TableCell>
                      <TableCell>
                        {row.created_at
                          ? new Date(row.created_at).toLocaleDateString()
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    style={{ textAlign: "center", fontSize: "1rem" }}
                  >
                    No record found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalDocs}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <TribeModal
        open={modalOpen}
        handleClose={handleModalClose}
        eventId={selectedEventId}
      />
    </Box>
  );
}
